import React, { useState } from 'react';
import logo from './../images/logo_small.png';
import { NavLink, useNavigate } from 'react-router-dom';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <>
            <header className="header-area">
                <div className="navgition navgition-transparent">
                    <div className="container">
                        <div className="row">
                            <div className="w-full">
                                <nav className="navbar navbar-expand-md flex items-center justify-between">
                                    <a className="navbar-brand mr-4" href="/">
                                        <img src={logo} alt="Logo" className='w-24 h-auto' />
                                    </a>

                                    <button
                                        className="navbar-toggler block focus:outline-none md:hidden"
                                        type="button"
                                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                                    >
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                    </button>

                                    <div className={`navbar-collapse absolute left-0 top-full z-30 w-full px-5 py-3 shadow-md transition-all duration-300 ease-in-out md:relative md:top-0 md:z-auto md:w-auto md:bg-transparent md:shadow-none ${isMenuOpen ? 'block' : 'hidden'} md:block`} id="navbarOne">
                                        <ul className="navbar-nav flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-4">
                                            <li>
                                                <NavLink
                                                    to="/"
                                                    onClick={() => { setIsMenuOpen(!isMenuOpen); }}
                                                    className={({ isActive }) =>
                                                        `nav-item ${isActive ? 'active' : ''}`
                                                    }
                                                >
                                                    <a class="page-scroll">BERANDA</a>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/about"
                                                    onClick={() => { setIsMenuOpen(!isMenuOpen); }}
                                                    className={({ isActive }) =>
                                                        `nav-item ${isActive ? 'active' : ''}`
                                                    }
                                                >
                                                    <a class="page-scroll">TENTANG KAMI</a>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/service"
                                                    onClick={() => { setIsMenuOpen(!isMenuOpen); }}
                                                    className={({ isActive }) =>
                                                        `nav-item ${isActive ? 'active' : ''}`
                                                    }
                                                >
                                                    <a class="page-scroll">SERVICE</a>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/career"
                                                    onClick={() => { setIsMenuOpen(!isMenuOpen); }}
                                                    className={({ isActive }) =>
                                                        `nav-item ${isActive ? 'active' : ''}`
                                                    }
                                                >
                                                    <a class="page-scroll">KARIR</a>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/contact"
                                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                                    className={({ isActive }) =>
                                                        `nav-item ${isActive ? 'active' : ''}`
                                                    }
                                                >
                                                    <a class="page-scroll">HUBUNGI</a>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="navbar-social d-none d-sm-flex align-items-center">
                                        <span>FOLLOW US</span>
                                        <ul>
                                            <li><a href="https://www.instagram.com/gstep_id/"><i className="lni-instagram-original"></i></a></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Navbar;