import React from "react";
import about from './../../images/img_about.jpg';
import Team from "./team";
import SectionAboutUs from "../home/about-us";

function AboutUsPage() {
    return (
        <>
            <SectionAboutUs />
            <Team />
        </>
    );
}

export default AboutUsPage;