import consultant from './../../images/service/project-management-consultancy.jpg';
import goods from './../../images/service/goods-procurement.jpg';

function ServiceConsultant() {
    return (
        <>
            <section id="service" className="services-area py-120 relative mb-8">
                <div className="container">
                    <div className="flex">
                        <div className="mx-4 w-full">
                            <div className="section-title pb-10">
                                <h4 className="title">CONSULTANT DAN PROCUREMENT MANAGEMENT
                                </h4>
                                <p className="text">
                                    Solusi bagi perusahaan untuk membantu merencanakan, mengelola, dan melaksanakan proses pengadaan barang dan jasa secara efektif dan efisien.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4 md:w-1/2 lg:w-1/2">
                            <div className="wow fadeInUp group mb-10 overflow-hidden rounded-[8px] border shadow-lg lg:h-[500px]">
                                <div className="mb-4 h-[250px] overflow-hidden rounded-t-[8px]">
                                    <a href="#" className="block">
                                        <img
                                            src={consultant}
                                            alt="Headhunting"
                                            className="h-[260px] w-full object-cover transition group-hover:scale-105"
                                        />
                                    </a>
                                </div>
                                <div className="p-3">
                                    <h3 className="text-dark mb-4 text-lg font-semibold hover:text-primary sm:text-xl lg:text-lg xl:text-xl dark:hover:text-primary">
                                        Project & Management Consultant
                                    </h3>
                                    <p className="text-body-color dark:text-dark-6 mb-2 text-base">
                                        Menyediakan layanan konsultasi yang komprehensif dalam perencanaan, pelaksanaan, dan pengelolaan proyek. Membantu organisasi mencapai tujuan strategis mereka melalui pengelolaan manajemen proyek yang efektif dan keunggulan operasional.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="w-full px-4 md:w-1/2 lg:w-1/2">
                            <div className="wow fadeInUp group mb-10 overflow-hidden rounded-[8px] border shadow-lg lg:h-[500px]">
                                <div className="mb-4 h-[280px] overflow-hidden rounded-t-[8px]">
                                    <a href="#" className="block">
                                        <img
                                            src={goods}
                                            alt="Service & Goods Procurement"
                                            className="h-[260px] w-full object-cover transition group-hover:scale-105"
                                        />
                                    </a>
                                </div>
                                <div className="p-3">
                                    <h3 className="text-dark mb-4 text-lg font-semibold hover:text-primary sm:text-xl lg:text-lg xl:text-xl dark:hover:text-primary">
                                        Service & Goods Procurement
                                    </h3>
                                    <p className="text-body-color dark:text-dark-6 mb-2 text-base">
                                        Penyediaan layanan pengadaan barang dan jasa yang terintegrasi untuk memenuhi kebutuhan bisnis dan organisasi secara efisien dan tepat waktu
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServiceConsultant;