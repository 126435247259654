import React from "react";
import SectionClient from "./client";
import SectionAboutUs from "./about-us";
import SectionServices from "./services";
import SectionBanner from "./banner";

const HomePage = () => {
  return (
    <>
      <SectionBanner />
      <SectionAboutUs />
      <SectionServices />
      <SectionClient />
      {/* <SectionCallToAction />
      <SectionCallToAction /> */}
    </>
  );
};

export default HomePage;