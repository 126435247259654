import procurement from './../../images/service/procurement.jpg';
import hrs from './../../images/service/man_power_supply.jpg';
import training from './../../images/banner-3.jpg';
import { NavLink } from "react-router-dom";

function ServicePage() {
    return (
        <>
            <section id="service" className="services-area">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap mt-8">
                        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                            <div className="wow fadeInUp group mb-10 overflow-hidden rounded-[8px] border shadow-lg lg:h-auto">
                                <div className="mb-4 h-[250px] overflow-hidden rounded-t-[8px]">
                                    <a href="#" className="block">
                                        <img
                                            src={procurement}
                                            alt="Headhunting"
                                            className="h-[260px] w-full object-cover transition group-hover:scale-105"
                                        />
                                    </a>
                                </div>
                                <div className="p-3">
                                    <h3 className="text-dark mb-4 text-lg font-semibold hover:text-primary sm:text-xl lg:text-lg xl:text-xl dark:hover:text-primary">
                                        Consultant & Procurement Management
                                    </h3>
                                    <p className="text-body-color dark:text-dark-6 mb-10 text-base">
                                        Solusi bagi perusahaan untuk membantu merencanakan, mengelola, dan melaksanakan proses pengadaan barang dan jasa secara efektif dan efisien.
                                    </p>
                                    <div className="text-center mb-10">
                                        <NavLink to="/service/consultant-procurement" className="hover:bg-primary-dark inline-block rounded-full bg-[#0067f4] px-6 py-2 text-sm font-medium text-white transition">
                                            Selengkapnya
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                            <div className="wow fadeInUp group mb-10 overflow-hidden rounded-[8px] border shadow-lg lg:h-auto">
                                <div className="mb-4 h-[280px] overflow-hidden rounded-t-[8px]">
                                    <a href="#" className="block">
                                        <img
                                            src={hrs}
                                            alt="Human Resource Solutions"
                                            className="h-[260px] w-full object-cover transition group-hover:scale-105"
                                        />
                                    </a>
                                </div>
                                <div className="p-3">
                                    <h3 className="text-dark mb-4 text-lg font-semibold hover:text-primary sm:text-xl lg:text-lg xl:text-xl dark:hover:text-primary">
                                        Human Resource Solutions
                                    </h3>
                                    <p className="text-body-color dark:text-dark-6 mb-8 text-base">
                                        Solusi bagi perusahaan yang ingin focus pada kegiatan utama bisnis. Kami memberikan solusi bagi perusahaan terkait pengelolaan karyawan, mulai dari rekrutmen hingga manajemen sumberdaya manusia.
                                    </p>
                                    <div className="text-center mb-4">
                                        <NavLink to="/service/human-resource" className="hover:bg-primary-dark inline-block rounded-full bg-[#0067f4] px-6 py-2 text-sm font-medium text-white transition">
                                            Selengkapnya
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                            <div className="wow fadeInUp group mb-10 overflow-hidden rounded-[8px] border shadow-lg lg:h-auto">
                                <div className="mb-4 h-[280px] overflow-hidden rounded-t-[8px]">
                                    <a href="#" className="block">
                                        <img
                                            src={training}
                                            alt="Training & HR Development"
                                            className="h-[260px] w-full object-cover transition group-hover:scale-105"
                                        />
                                    </a>
                                </div>
                                <div className="p-3">
                                    <h3 className="text-dark mb-4 text-lg font-semibold hover:text-primary sm:text-xl lg:text-lg xl:text-xl dark:hover:text-primary">
                                        Training & HR Development
                                    </h3>
                                    <p className="text-body-color dark:text-dark-6 mb-8 text-base">
                                        Layanan yang berfokus pada pengembangan keterampilan, pengetahun dan kemampuan sumberdaya manusia untuk meningkatkan kinerja individu serta mencapai tujuan organisasi yang lebih efektif
                                    </p>
                                    <div className="text-center mb-4">
                                        <NavLink to="/service/training-service" className="hover:bg-primary-dark inline-block rounded-full bg-[#0067f4] px-6 py-2 text-sm font-medium text-white transition">
                                            Selengkapnya
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicePage;