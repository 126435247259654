import headhunting from './../../images/service/headhunting.jpg';
import consultant from './../../images/service/project-management-consultancy.jpg';

function ServiceHumanResource() {
    return (
        <>
            <section id="service" className="services-area relative mb-8 pb-[100px]">
                <div className="container">
                    <div className="flex">
                        <div className="mx-4 w-full">
                            <div className="section-title pb-10">
                                <h4 className="title">HUMAN RESOURCE SOLUTIONS</h4>
                                <p className="text">
                                    Solusi bagi perusahaan yang ingin fokus pada kegiatan utama bisnis. Kami memberikan solusi bagi perusahaan terkait pengelolaan karyawan, mulai dari rekrutmen hingga manajemen sumber daya manusia.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4 md:w-1/2 lg:w-1/2">
                            <div className="wow fadeInUp group mb-10 overflow-hidden rounded-[8px] border shadow-lg lg:h-[500px]">
                                <div className="mb-4 h-[250px] overflow-hidden rounded-t-[8px]">
                                    <a href="#" className="block">
                                        <img
                                            src={headhunting}
                                            alt="Headhunting"
                                            className="h-[260px] w-full object-cover transition group-hover:scale-105"
                                        />
                                    </a>
                                </div>
                                <div className="p-3">
                                    <h3 className="text-dark mb-4 text-lg font-semibold hover:text-primary sm:text-xl lg:text-lg xl:text-xl dark:hover:text-primary">
                                        Headhunting
                                    </h3>
                                    <p className="text-body-color dark:text-dark-6 mb-2 text-base">
                                        Layanan perekrutan & penyaluran kandidat yang efektif dan efisien, memastikan kesesuaian antara keahlian kandidat  dengan kebutuhan perusahaan. Pendekatan ini adalah cara kami mendukung Anda dalam mengembangkan bisnis.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="w-full px-4 md:w-1/2 lg:w-1/2">
                            <div className="wow fadeInUp group mb-10 overflow-hidden rounded-[8px] border shadow-lg lg:h-[500px]">
                                <div className="mb-4 h-[280px] overflow-hidden rounded-t-[8px]">
                                    <a href="#" className="block">
                                        <img
                                            src={consultant}
                                            alt="Outsourcing & HR Management"
                                            className="h-[260px] w-full object-cover transition group-hover:scale-105"
                                        />
                                    </a>
                                </div>
                                <div className="p-3">
                                    <h3 className="text-dark mb-4 text-lg font-semibold hover:text-primary sm:text-xl lg:text-lg xl:text-xl dark:hover:text-primary">
                                        Outsourcing & HR Management
                                    </h3>
                                    <p className="text-body-color dark:text-dark-6 mb-2 text-base">
                                        Layanan Pengelolaan sumber daya manusia yang efekfif dan efisien serta terintegrasi dengan strategi bisnis perusahaan. Layanan menyeluruh dalam memenuhi dan mengelola kebutuhan sumber daya manusia perusahaan.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-center mt-20">
                        <div className="w-full">
                            <div className="section-title pb-10">
                                <h6 className="text-lg font-semibold text-gray-900">Beberapa Posisi yang bisa kami support untuk perusahaan anda : </h6>
                            </div>

                            <div className="row testimonial-active">
                                <div className="w-full lg:w-1/4 px-2">
                                    <div className="single-testimonial text-center">
                                        <div className="testimonial-content">
                                            <div class="single-pricing">
                                                <div class="pricing-header text-center">
                                                    <h5 class="sub-title"> Front Office Support</h5>
                                                </div>
                                                <div class="pricing-list">
                                                    <ul>
                                                        <li><i class="lni-check-mark-circle"></i> Receptionist</li>
                                                        <li><i class="lni-check-mark-circle"></i> Customer Service</li>
                                                        <li><i class="lni-check-mark-circle"></i> Teller</li>
                                                        <li><i class="lni-check-mark-circle"></i> Call Center </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/4 px-2">
                                    <div className="single-testimonial text-center">
                                        <div className="testimonial-content">
                                            <div class="single-pricing">
                                                <div class="pricing-header text-center">
                                                    <h5 class="sub-title"> Back Office Support</h5>
                                                </div>
                                                <div class="pricing-list">
                                                    <ul>
                                                        <li><i class="lni-check-mark-circle"></i> Admin</li>
                                                        <li><i class="lni-check-mark-circle"></i> Data Entry</li>
                                                        <li><i class="lni-check-mark-circle"></i> Marketing Support</li>
                                                        <li><i class="lni-check-mark-circle"></i> IT</li>
                                                        <li><i class="lni-check-mark-circle"></i> Accounting / Finance Staff</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/4 px-2">
                                    <div className="single-testimonial text-center">
                                        <div className="testimonial-content">
                                            <div class="single-pricing">
                                                <div class="pricing-header text-center">
                                                    <h5 class="sub-title"> Office & Building Support</h5>
                                                </div>
                                                <div class="pricing-list">
                                                    <ul>
                                                        <li><i class="lni-check-mark-circle"></i> Office Boy</li>
                                                        <li><i class="lni-check-mark-circle"></i> Housekeeping</li>
                                                        <li><i class="lni-check-mark-circle"></i> Mechanical Engineering</li>
                                                        <li><i class="lni-check-mark-circle"></i> Cleaning Service</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/4 px-2">
                                    <div className="single-testimonial text-center">
                                        <div className="testimonial-content">
                                            <div class="single-pricing">
                                                <div class="pricing-header text-center">
                                                    <h5 class="sub-title"> Operational Support</h5>
                                                </div>
                                                <div class="pricing-list">
                                                    <ul>
                                                        <li><i class="lni-check-mark-circle"></i> Driver</li>
                                                        <li><i class="lni-check-mark-circle"></i> Waiter / Resto Crew</li>
                                                        <li><i class="lni-check-mark-circle"></i> Food & Beverage</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServiceHumanResource;