import headerBg from '../../images/banner.jpg';
import banner2 from '../../images/banner-2.jpg';
import banner3 from '../../images/banner-3.jpg';
import { NavLink } from 'react-router-dom';
import React, { useState, useEffect } from "react";

function SectionBanner() {
    const bannerImages = [headerBg, banner2, banner3];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [opacity, setOpacity] = useState(1);

    // Function to go to the next image
    const nextImage = () => {
        setOpacity(0);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerImages.length);
            setOpacity(1);
        }, 500);
    };

    // Auto-slide effect
    useEffect(() => {
        const interval = setInterval(nextImage, 4000); // Change image every 4 seconds
        return () => clearInterval(interval); // Clean up interval on unmount
    }, []);

    return (
        <>
            <div id="home" className={`header-hero bg_cover transition-opacity duration-1000`} style={{ backgroundImage: `url(${bannerImages[currentIndex]})`, opacity: opacity }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10 mt-16">
                            <div className="header-content text-center">
                                <h3 className="header-title">GSTEP Indonesia</h3>
                                <p className="text">ProSkill Academy & Human Resource Solutions</p>
                                <ul className="header-btn">
                                    <li><a class="main-btn btn-two" rel="nofollow" href="/about">TENTANG KAMI</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionBanner;