import React from "react";
import about from './../../images/img_about.jpg';

function SectionAboutUs() {
    return (
        <section id="about" className="services-area">
            <div className="container">
                <div className="row justify-content-start">
                    <div className="col-lg-8">
                        <div className="col-md-6">
                            <div className="section-title text-left">
                                <h4 className="title">Tentang Kami</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="col-md-10">
                            <div className="services-content">
                                <div className="services-content text-left">
                                    <p className="text"><strong>GStep Indonesia (PT Langkah Baik Indonesia)</strong> bagian unit bisnis dari Cleansheet Group merupakan perusahaan profesional penyedia pengadaan barang dan jasa facility services, pelatihan dan pengelolaan jasa untuk kebutuhan sumber daya manusia (SDM).</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-start mt-8">
                    <div className="col-lg-8">
                        <div className="col-md-6">
                            <div className="section-title text-left">
                                <h5 className="title">Visi</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="col-md-10">
                            <div className="services-content">
                                <div className="services-content text-left">
                                    <p className="text">Solusi bagi perusahaan yang ingin focus pada kegiatan utama bisnis. Kami memberikan solusi bagi perusahaan terkait pengelolaan karyawan, mulai dari rekrutmen hingga manajemen sumberdaya manusia.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-start mt-8">
                    <div className="col-lg-8">
                        <div className="col-md-6">
                            <div className="section-title text-left">
                                <h5 className="title">Misi</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="col-md-10">
                            <div className="services-content">
                                <div class="pricing-list text-left ml-3">
                                    <ul>
                                        <li><i class="lni-check-mark-circle"></i> Pengembangan Sumber Daya Manusia</li>
                                        <li><i class="lni-check-mark-circle"></i> Penyaluran dan Penyediaan Sumber Daya Manusia</li>
                                        <li><i class="lni-check-mark-circle"></i> Konsultasi dan Manajemen Pengadaan</li>
                                        <li><i class="lni-check-mark-circle"></i> Kemitraan dan Kolaborasi</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-image d-lg-flex align-items-center mr-25">
                <div className="image">
                    <img src={about} alt="Services" />
                </div>
            </div>
        </section>
    );
}

export default SectionAboutUs;