import logo from './../logo.svg';
import cleansheet from './../images/client/cleansheet.png';
import gstep from './../images/logo_small.png'
import { NavLink } from "react-router-dom";

function Footer() {
    return (
        <>
            <div className="footer-widget">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-7">
                            <div className="footer-link text-left">
                                <h6 className="footer-title">GSTEP Indonesia</h6>
                                <div className="flex flex-row gap-4 mt-4 mb-4">
                                    <img src={gstep} alt="gstep" className="w-24 h-16" />
                                    <img src={cleansheet} alt="cleansheet" className="w-24 h-16" />
                                </div>
                                <p className="text">Member of Cleansheet Group</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <div className="footer-link text-left">
                                <h6 className="footer-title">Info</h6>
                                <ul className='flex flex-col'>
                                    <NavLink to="/about">
                                        <li><a>Tentang Kami</a></li>
                                    </NavLink>
                                    <NavLink to="/career">
                                        <li><a>Karir</a></li>
                                    </NavLink>
                                    <NavLink to="/job">
                                        <li><a>Lowongan</a></li>
                                    </NavLink>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="footer-link text-left">
                                <h6 className="footer-title">Service</h6>
                                <ul className='flex flex-col'>
                                    <NavLink to="/service/consultant-procurement">
                                        <li><a>Consultant & Procurement Management</a></li>
                                    </NavLink>
                                    <NavLink to="/service/human-resource">
                                        <li><a>Human Resource Solutions</a></li>
                                    </NavLink>
                                    <NavLink to="/service/training-service">
                                        <li><a>Training & HR Development</a></li>
                                    </NavLink>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-5">
                            <div className="footer-link text-left">
                                <h6 className="footer-title">Hubungi Kami</h6>
                                <ul>
                                    <li><a href="#"><i className="lni-phone-handset" /> +62 8593 5140 788</a></li>
                                    <li><a href="#"><i className="lni-envelope" /> marketing@gstepindonesia.id</a></li>
                                    <li><a href="#"><i className="lni-map-marker" /> Head Office : <br />Startup Center IPB <br /> Jl. Taman Kencana No.3, Babakan, Kec. Bogor Tengah, Kota Bogor, Jawa Barat 16128</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="copyright text-center">
                                <p className="text">Hak Cipta © 2024 GSTEP Indonesia</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <a className="back-to-top" href="#"><i className="lni-chevron-up"></i></a>
        </>
    );
}

export default Footer;
