import procurement from './../../images/ic_procurement.png';
import hiring from './../../images/ic_hiring.png';
import mentor from './../../images/ic_mentor.png';
import React from "react";
import { NavLink } from "react-router-dom";

function SectionServices() {
    return (
        <section id="pricing" className="pricing-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section-title text-center pb-10">
                            <h4 className="title">Layanan Kami</h4>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-7 col-sm-9">
                        <div className="single-pricing mt-8">
                            <div className="pricing-baloon d-flex justify-content-center align-items-center mt-4">
                                <img src={procurement} alt="baloon" className="w-24 h-auto" />
                            </div>
                            <div className="pricing-header text-center mt-8">
                                <h5 className="sub-title">Consultant & Procurement Management</h5>
                            </div>
                            <div className="pricing-list media-body">
                                <p className="text">Solusi bagi perusahaan untuk membantu merencanakan, mengelola, dan melaksanakan proses pengadaan barang dan jasa secara efektif dan efisien.</p>
                            </div>
                            <div className="pricing-btn text-center">
                                <NavLink to="/service/consultant-procurement" className="main-btn">
                                    Selengkapnya
                                </NavLink>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-7 col-sm-9">
                        <div className="single-pricing mt-8">
                            <div className="pricing-baloon d-flex justify-content-center align-items-center mt-4">
                                <img src={hiring} alt="baloon" className="w-24 h-auto" />
                            </div>
                            <div className="pricing-header text-center mt-8">
                                <h5 className="sub-title">Human Resource Solutions</h5>
                            </div>
                            <div className="pricing-list media-body">
                                <p className="text">Solusi bagi perusahaan yang ingin focus pada kegiatan utama bisnis. Kami memberikan solusi bagi perusahaan terkait pengelolaan karyawan, mulai dari rekrutmen hingga manajemen sumberdaya manusia.</p>
                            </div>
                            <div className="pricing-btn text-center">
                                <NavLink to="/service/human-resource" className="main-btn">
                                    Selengkapnya
                                </NavLink>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-7 col-sm-9">
                        <div className="single-pricing mt-8">
                            <div className="pricing-flower d-flex justify-content-center align-items-center mt-4">
                                <img src={mentor} alt="flower" className="w-24 h-auto" />
                            </div>
                            <div className="pricing-header text-center mt-8">
                                <h5 className="sub-title">Training & HR Development</h5>
                            </div>
                            <div className="pricing-list media-body">
                                <p className="text">Layanan yang berfokus pada pengembangan keterampilan, pengetahun dan kemampuan sumberdaya manusia untuk meningkatkan kinerja individu serta mencapai tujuan organisasi yang lebih efektif</p>
                            </div>
                            <div className="pricing-btn text-center">
                                <NavLink to="/service/training-service" className="main-btn">
                                    Selengkapnya
                                </NavLink>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionServices;