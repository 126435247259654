import cleansheet from './../../images/client/cleansheet.png';
import arcadia from './../../images/client/arcadia.png';
import ipb from './../../images/client/ipb.png';
import studiorenang from './../../images/client/studiorenang.png';
import ozlezzat from './../../images/client/ozlezzat.jpeg';
import larsen from './../../images/client/larsen.png';
import pasi from './../../images/client/pasi.png';
import pramoo from './../../images/client/pramoo.png';
import brin from './../../images/client/brin.png';
import ipbhotel from './../../images/client/ipb-hotel.png';

function SectionClient() {
    return (
        <section id="client" className="contact-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section-title text-center pb-10">
                            <h4 className="title">Klien Kami</h4>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center gap-12">
                    <img src={cleansheet} alt="mer-c" className="w-auto h-16" />
                    <img src={arcadia} alt="mer-c" className="w-auto h-16" />
                    <img src={ipb} alt="mer-c" className="w-auto h-16" />
                    <img src={studiorenang} alt="mer-c" className="w-auto h-16" />
                    <img src={ozlezzat} alt="mer-c" className="w-auto h-16" />
                    <img src={larsen} alt="mer-c" className="w-auto h-16" />
                    <img src={pasi} alt="mer-c" className="w-auto h-16" />
                    <img src={pramoo} alt="mer-c" className="h-32 w-32" />
                    <img src={brin} alt="mer-c" className="w-auto h-24" />
                    <img src={ipbhotel} alt="mer-c" className="w-auto h-32" />
                </div>
            </div>
        </section>
    );
}

export default SectionClient;