function ContactPage() {
    return (
        <>
            <section id="service" className="services-area py-100 relative mb-8">
                <div className="container">
                    <div className="flex flex-col">
                        <div className="mx-4 w-full">
                            <div className="section-title pb-10">
                                <h4 className="title">HUBUNGI KAMI
                                </h4>
                            </div>
                        </div>
                        <div className="w-full">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8495.920063973539!2d106.80012372632929!3d-6.5862608547437205!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa4863d0608ac2f7d%3A0xbef7395529ed11ea!2sStartup%20Center%20IPB!5e0!3m2!1sen!2sid!4v1733667427997!5m2!1sen!2sid"
                                width="100%"
                                height="400"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-5">
                            <div className="footer-link text-left">
                                <ul>
                                    <li><a href="#"><i className="lni-phone-handset" /> +62 8593 5140 788</a></li>
                                    <li><a href="#"><i className="lni-envelope" /> marketing@gstepindonesia.id</a></li>
                                    <li><a href="#"><i className="lni-map-marker" /> Head Office : <br />Startup Center IPB <br /> Jl. Taman Kencana No.3, Babakan, Kec. Bogor Tengah, Kota Bogor, Jawa Barat 16128</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContactPage;
