import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import Layout from "./layout";
import HomePage from "./pages/home/home";
import AboutUsPage from "./pages/about";
import ServicePage from "./pages/service";
import ServiceConsultant from "./pages/service/consultant";
import ServiceHumanResource from "./pages/service/human-resource";
import ServiceTraining from "./pages/service/training";
import CareerPage from "./pages/career";
import ContactPage from "./pages/contact";
import JobPage from "./pages/career/job";

function App() {
  return (
    <Wrapper>
          <Routes>
            <Route path={"/"} element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path={"/about"} element={<AboutUsPage />} />
              <Route path={"/service"}>
                <Route index element={<ServicePage />} />
                <Route
                  path={"/service/consultant-procurement"}
                  element={<ServiceConsultant />}
                />
                <Route
                  path={"/service/human-resource"}
                  element={<ServiceHumanResource />}
                />
                <Route
                  path={"/service/training-service"}
                  element={<ServiceTraining />}
                />
              </Route>
              <Route path={"/career"} element={<CareerPage />} />
              <Route path={"/contact"} element={<ContactPage />} />
              <Route path={"/job"} element={<JobPage />} />
            </Route>
          </Routes>
        
    </Wrapper>
  );
}

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default App;
