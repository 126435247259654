function JobPage() {
    return (
        <>
            <section id="service" className="services-area py-120 relative mb-8">
                <div className="container">
                    <div className="flex">
                        <div className="mx-4 w-full">
                            <div className="section-title pb-10">
                                <h4 className="title">LOWONGAN
                                </h4>
                                <p className="text">
                                    Coming Soon!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default JobPage;