import maincommissioner from './../../images/teams/main_commissioner.png';
import commissioner from './../../images/teams/commissioner.png';
import ceo from './../../images/teams/ceo.png';
import advisor from './../../images/teams/advisor.png';
import operation from './../../images/teams/operation.png';
import hrm from './../../images/teams/hrm.png';
import development from './../../images/teams/development.png';

function Team() {
    return (
        <>
            <section id="testimonial" className="py-100 mb-16">
                <div className="container">
                    <div className="row justify-center">
                        <div className="mx-4 w-full lg:w-1/2">
                            <div className="section-title pb-20 text-center">
                                <h4 className="title">Our Management</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-center mb-16">
                        <div className="w-full">
                            <div className="row testimonial-active">
                                <div className="w-full lg:w-1/3">
                                    <div className="single-testimonial text-center">
                                        <div className="testimonial-image">
                                            <img
                                                src={maincommissioner}
                                                alt="Author"
                                                className="w-48 h-48 object-top object-cover"
                                            />
                                        </div>
                                        <div className="testimonial-content">
                                            <h6 className="text-lg font-semibold text-gray-900">
                                                Albert R. Soetanto
                                            </h6>
                                            <span className="text-sm text-gray-700">
                                                Main Commissioner
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/3">
                                    <div className="single-testimonial text-center">
                                        <div className="testimonial-image">
                                            <img
                                                src={commissioner}
                                                alt="Author"
                                                className="w-48 h-48 object-top object-cover"
                                            />
                                        </div>
                                        <div className="testimonial-content">
                                            <h6 className="text-lg font-semibold text-gray-900">
                                                Dihqon Nadaamist
                                            </h6>
                                            <span className="text-sm text-gray-700">Commissioner</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/3">
                                    <div className="single-testimonial text-center">
                                        <div className="testimonial-image">
                                            <img
                                                src={ceo}
                                                alt="Author"
                                                className="w-48 h-48 object-top object-cover"
                                            />
                                        </div>
                                        <div className="testimonial-content">
                                            <h6 className="text-lg font-semibold text-gray-900">
                                                Agus Handoko Simangunsong
                                            </h6>
                                            <span className="text-sm text-gray-700">CEO</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-center">
                        <div className="w-full">
                            <div className="row testimonial-active">
                                <div className="w-full lg:w-1/4">
                                    <div className="single-testimonial text-center">
                                        <div className="testimonial-image">
                                            <img
                                                src={advisor}
                                                alt="Author"
                                                className="w-48 h-48 object-top object-cover"
                                            />
                                        </div>
                                        <div className="testimonial-content">
                                            <h6 className="text-lg font-semibold text-gray-900">
                                                Albertus Henny Hindarto
                                            </h6>
                                            <span className="text-sm text-gray-700">
                                                Advisor
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/4">
                                    <div className="single-testimonial text-center">
                                        <div className="testimonial-image">
                                            <img
                                                src={operation}
                                                alt="Author"
                                                className="w-48 h-48 object-top object-cover"
                                            />
                                        </div>
                                        <div className="testimonial-content">
                                            <h6 className="text-lg font-semibold text-gray-900">
                                                M. Sirojul Abidin
                                            </h6>
                                            <span className="text-sm text-gray-700">Head Of Operation</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/4">
                                    <div className="single-testimonial text-center">
                                        <div className="testimonial-image">
                                            <img
                                                src={hrm}
                                                alt="Author"
                                                className="w-48 h-48 object-top object-cover"
                                            />
                                        </div>
                                        <div className="testimonial-content">
                                            <h6 className="text-lg font-semibold text-gray-900">
                                                Seno Aji Wibowo
                                            </h6>
                                            <span className="text-sm text-gray-700">Head HRM Operation</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/4">
                                    <div className="single-testimonial text-center">
                                        <div className="testimonial-image">
                                            <img
                                                src={development}
                                                alt="Author"
                                                className="w-48 h-48 object-top object-cover"
                                            />
                                        </div>
                                        <div className="testimonial-content">
                                            <h6 className="text-lg font-semibold text-gray-900">
                                                Reza Taruna
                                            </h6>
                                            <span className="text-sm text-gray-700">Head Of Learning and Development Operation</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Team;