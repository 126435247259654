import React from "react";
import Navbar from "./layouts/navbar";
import { Outlet } from "react-router-dom";
import Footer from "./layouts/footer";
import './App.css';

const Layout = () => {
  return (
    <>
      <div className="App">
        <header className="header-area">
          <Navbar />
        </header>
        <main id="main">
          <Outlet />
        </main>
        <footer id="footer" className="footer-area">
          <Footer />
        </footer>
      </div>
    </>
  );
};

export default Layout;