import training from './../../images/banner-3.jpg';

function ServiceTraining() {
    return (
        <>
            <section id="service" className="services-area py-120 relative mb-8">
                <div className="container">
                    <div className="flex">
                        <div className="mx-4 w-full">
                            <div className="section-title pb-10">
                                <h4 className="title">TRAINING & HR DEVELOPMENT</h4>
                                <p className="text">
                                    Layanan yang berfokus pada pengembangan keterampilan, pengetahuan dan kemampuan sumberdaya manusia untuk meningkatkan kinerja individu serta mencapai tujuan organisasi yang lebih efektif.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-20 overflow-hidden rounded-t-[8px] flex justify-center">
                        <a href="#" className="block">
                            <img
                                src={training}
                                alt="Training"
                                className="h-auto max-w-2xl object-cover transition group-hover:scale-105"
                            />
                        </a>
                    </div>
                    <div className="w-full flex justify-center mb-20">
                        <div className="single-testimonial text-center">
                            <div className="testimonial-content">
                                <div class="single-pricing">
                                    <div class="pricing-header text-center">
                                        <h5 class="sub-title">Mengapa Anda memilih kami ?</h5>
                                    </div>
                                    <div className="pricing-list">
                                        <ul>
                                            <li><i class="lni-check-mark-circle custom-checkmark"></i> Partner belajar yang menemani pertumbuhan dan perkembangan setiap prosesnya</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark"></i> Meningkatkan citra lembaga, organisasi dan institusi dalam pembelajaran</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark"></i> Memenuhi ekspektasi sesuai harapan mitra</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark"></i> Fleksibel dan menyesuaikan dengan waktu dan kebutuhan anda</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark"></i> Menggunakan metode pembelajaran terkini sehingga mudah diterima</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex justify-center">
                        <div className="single-testimonial text-center">
                            <div className="testimonial-content">
                                <div class="single-pricing">
                                    <div class="pricing-header text-center">
                                        <h5 className="sub-title">Beberapa Materi Training yang kami tawarkan : </h5>
                                    </div>
                                    <div className="pricing-list">
                                        <ul>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Service Excellent</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Networking Skill</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Complaint Handling</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Time Management</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Sales & Marketing Operation</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Human Capital Operation</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Cust. Service and Cust. Relation Operation</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Product Development</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Business Operation</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Restaurant Budget Forecast</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Leadership Development</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Masa Persiapan Pensiun</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Quality Assurance Operation</li>
                                            <li><i class="lni-check-mark-circle custom-checkmark mb-2"></i> Training Program Lainnya</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServiceTraining;